
    import DomainDetails from "@/components/DomainDetails.vue";
    import Realms from "@/components/Realms.vue";
    import { createDomain } from "@/service/domains";
    import { DomainStatusDTO } from "@/model/dto/domain_v2";
    import { RealmsBodyDTO } from "@/model/dto/realms";
    import { defineComponent, Ref, ref } from "vue";

    export default defineComponent({
        name: "DomainOnboarding",
        components: { Realms, DomainDetails },
        emits: ["onboarding:created"],
        setup(_, { emit }) {
            const realmsRef: Ref<any> = ref(null);
            const selectedDomain: Ref<DomainStatusDTO | null> = ref(null);
            const domain: Ref<string> = ref("");
            const wxp: Ref<boolean> = ref(false);
            const responsible: Ref<string> = ref("");
            const realms: Ref<RealmsBodyDTO[]> = ref([]);
            const loading: Ref<boolean> = ref(false);
            const error: Ref<string | null> = ref(null);
            const modal: Ref<boolean> = ref(false);

            const domainPattern = new RegExp("^([a-z0-9-\*]+\\.)+[a-z0-9-\*]+$", "i");
            // see http://www.w3.org/TR/html5/forms.html#valid-e-mail-address
            const emailPattern = new RegExp(
                "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
                "i",
            );

            const submit = async () => {
                error.value = null;

                realmsRef.value.newRealm();

                domain.value = domain.value.trim().toLowerCase();
                responsible.value = responsible.value.trim();
                if (!domainPattern.test(domain.value)) {
                    error.value = "Please enter a valid domain.";
                    return;
                }
                if (!emailPattern.test(responsible.value)) {
                    error.value = "Please enter a valid responsible.";
                    return;
                }

                loading.value = true;
                try {
                    const result: DomainStatusDTO = await createDomain(domain.value, {
                        responsible: responsible.value,
                        realms: realms.value,
                        external: wxp.value,
                    });
                    console.log(result);
                    domain.value = "";
                    responsible.value = "";
                    wxp.value = false;
                    realms.value = [];
                    if (result) {
                        selectedDomain.value = result;
                        modal.value = true;
                    } else {
                        emit("onboarding:created");
                    }
                } catch (e: any) {
                    error.value =
                        "No results could be generated. Please check your network connection.";
                } finally {
                    loading.value = false;
                }
            };

            const closeModal = () => {
                modal.value = false;
                selectedDomain.value = null;
                emit("onboarding:created");
            };

            return {
                realmsRef,
                selectedDomain,
                domain,
                responsible,
                realms,
                wxp,
                loading,
                error,
                modal,
                submit,
                closeModal,
            };
        },
    });
