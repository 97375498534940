
    import { CertificateStatus as CertStatus, DomainStatusDTO } from "@/model/dto/domain_v2";
    import { flattenRealms, getCertificatePath } from "@/composables/DomainStatusList";
    import { formattedDate } from "@/util/i18n";
    import { defineComponent, PropType } from "vue";
    import DNSValidation from "@/components/DNSValidation.vue";
    import CertificateStatus from "@/components/CertificateStatus.vue";
    import AcceleratorStatus from "@/components/AcceleratorStatus.vue";
    import ValidationRecordDetails from "@/components/ValidationRecordDetails.vue";
    import ApiKeys from "@/components/ApiKeys.vue";

    export default defineComponent({
        name: "DomainsDetails",
        components: {
            ApiKeys,
            ValidationRecordDetails,
            DNSValidation,
            CertificateStatus,
            AcceleratorStatus,
        },
        props: {
            domain: {
                type: Object as PropType<DomainStatusDTO>,
                required: true,
            },
            editable: {
                type: Boolean,
                default: true,
            },
        },
        emits: ["reload"],
        setup(props, { emit }) {
            const external: boolean =
                props.domain.currentCertStatus.toLowerCase() === CertStatus.NONE;

            const reloadDomain = () => {
                emit("reload");
            };
            return {
                formattedDate,
                getCertificatePath,
                flattenRealms,
                external,
                reloadDomain,
            };
        },
    });
