import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = {
  key: 0,
  class: "error"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_inputtext = _resolveComponent("prime-inputtext")!
  const _component_prime_checkbox = _resolveComponent("prime-checkbox")!
  const _component_Realms = _resolveComponent("Realms")!
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_DomainDetails = _resolveComponent("DomainDetails")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      action: "javascript:void 0",
      class: "p-fluid",
      onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submit()))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_prime_inputtext, {
          modelValue: _ctx.domain,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.domain) = $event)),
          placeholder: "example.com"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_prime_checkbox, {
          modelValue: _ctx.wxp,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wxp) = $event)),
          binary: true
        }, null, 8, ["modelValue"]),
        _cache[6] || (_cache[6] = _createTextVNode(" is WXP SPA domain "))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_prime_inputtext, {
          modelValue: _ctx.responsible,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.responsible) = $event)),
          placeholder: "responsible@example.com"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Realms, {
          ref: "realmsRef",
          modelValue: _ctx.realms,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.realms) = $event)),
          placeholder: "Add a realm"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_prime_button, {
        type: "submit",
        icon: "pi pi-check",
        label: "Submit"
      })
    ], 32),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[7] || (_cache[7] = [
          _createElementVNode("i", { class: "pi pi-spin pi-spinner" }, null, -1),
          _createTextVNode(" Loading ")
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_prime_dialog, {
      visible: _ctx.modal,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modal) = $event)),
      modal: true,
      onHide: _ctx.closeModal
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "newdomainflag" }, "Created ", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.selectedDomain!.name), 1)
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_prime_button, {
          label: "OK",
          icon: "pi pi-check",
          autofocus: "",
          onClick: _ctx.closeModal
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DomainDetails, {
          domain: _ctx.selectedDomain!,
          editable: false
        }, null, 8, ["domain"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}