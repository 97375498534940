import { Validation, ValidationRecord } from "@/model/redirect";

export interface ProblemDTO {
    domainName: string;
    ip: string;
    validationRecord: ValidationRecord;
    domainIpValidationStatus: Validation;
    validationRecordStatus: Validation;
}

export function composeCheckedMessage(validation: Validation) {
    return "Checked: " + validation.checked;
}
