import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import "@/service/aws/session";

import PrimeVue from "primevue/config";
import Button from "primevue/button/Button.vue";
import Checkbox from "primevue/checkbox/Checkbox.vue";
import Dialog from "primevue/dialog/Dialog.vue";
import Dropdown from "primevue/dropdown/Dropdown.vue";
import InputText from "primevue/inputtext/InputText.vue";
import Chips from "primevue/chips/Chips.vue";
import Listbox from "primevue/listbox/Listbox.vue";
import Message from "primevue/message/Message.vue";
import Password from "primevue/password/Password.vue";
import FileUpload from "primevue/fileupload/FileUpload.vue";
import ProgressSpinner from "primevue/progressspinner/ProgressSpinner.vue";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import DataTable from "primevue/datatable/DataTable.vue";
import Paginator from "primevue/paginator/Paginator.vue";
import Column from "primevue/column/Column.vue";
import TabView from "primevue/tabview/TabView.vue";
import TabPanel from "primevue/tabpanel/TabPanel.vue";
import Panel from "primevue/panel/Panel.vue";
import ContextMenu from "primevue/contextmenu/ContextMenu.vue";
import MultiSelect from "primevue/multiselect/MultiSelect.vue";
import VirtualScroller from "primevue/virtualscroller/VirtualScroller.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete.vue";
import ProgressBar from "primevue/progressbar/ProgressBar.vue";
import ConfirmDialog from "primevue/confirmdialog/ConfirmDialog.vue";
import Tooltip from "primevue/tooltip";
import StyleClass from "primevue/styleclass";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.component("PrimeButton", Button);
app.component("PrimeAutoComplete", AutoComplete);
app.component("PrimeDialog", Dialog);
app.component("PrimeInputtext", InputText);
app.component("PrimeChips", Chips);
app.component("PrimeDropdown", Dropdown);
app.component("PrimeCheckbox", Checkbox);
app.component("PrimeListbox", Listbox);
app.component("PrimeMessage", Message);
app.component("PrimePassword", Password);
app.component("PrimeFileupload", FileUpload);
app.component("PrimeDatatable", DataTable);
app.component("PrimeColumn", Column);
app.component("PrimeSpinner", ProgressSpinner);
app.component("PrimeTabview", TabView);
app.component("PrimeTabpanel", TabPanel);
app.component("PrimePanel", Panel);
app.component("PrimeMultiSelect", MultiSelect);
app.component("PrimeContextMenu", ContextMenu);
app.component("PrimePaginator", Paginator);
app.component("PrimeVirtualScroller", VirtualScroller);
app.component("PrimeProgressBar", ProgressBar);
app.component("PrimeConfirmDialog", ConfirmDialog);
app.directive("tooltip", Tooltip);
app.directive("styleclass", StyleClass);
app.directive("focus", {
    // When the bound element is mounted into the DOM...
    mounted(el) {
        // Focus the element
        el.focus();
    },
});
app.mount("#app");
