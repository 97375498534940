import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import { Ref } from "@vue/reactivity";

export default (): Ref => {
    const route = useRoute();
    const result = ref(route.params.id);
    watch(route, () => {
        result.value = route.params.id;
    });
    return result;
};
