import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "basf-realms" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_auto_complete = _resolveComponent("prime-auto-complete")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_prime_auto_complete, {
      ref: "autoCompleteRef",
      modelValue: _ctx.selectedRealms,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRealms) = $event)),
      multiple: true,
      suggestions: _ctx.suggestedRealms,
      field: "name",
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addRealm($event))),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.newRealm()), ["stop","prevent"]), ["enter"])),
      onKeypress: _withKeys(_withModifiers(() => {}, ["stop","prevent"]), ["enter"])
    }, null, 8, ["modelValue", "suggestions", "placeholder", "disabled", "onKeypress"])
  ]))
}