import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "certificate"
}
const _hoisted_2 = { class: "certnavigation" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_message = _resolveComponent("prime-message")!
  const _component_prime_column = _resolveComponent("prime-column")!
  const _component_DomainStatus = _resolveComponent("DomainStatus")!
  const _component_DNSValidation = _resolveComponent("DNSValidation")!
  const _component_prime_datatable = _resolveComponent("prime-datatable")!
  const _component_CertificateStatus = _resolveComponent("CertificateStatus")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Certificates Details", -1)),
    (_ctx.loadingError)
      ? (_openBlock(), _createBlock(_component_prime_message, {
          key: 0,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Error loading certificate info, please try again ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.regions)
            ? (_openBlock(), _createBlock(_component_prime_datatable, {
                key: 0,
                value: _ctx.domainEntries,
                "responsive-layout": "scroll",
                class: "domain-table"
              }, {
                header: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", null, "Domains Table", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_prime_column, {
                    header: "Domain",
                    field: "name"
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regions, (reg) => {
                    return (_openBlock(), _createBlock(_component_prime_column, {
                      key: reg,
                      header: reg,
                      field: reg
                    }, {
                      body: _withCtx((slot) => [
                        _createVNode(_component_DomainStatus, {
                          status: slot.data[slot.column.key]
                        }, null, 8, ["status"])
                      ]),
                      _: 2
                    }, 1032, ["header", "field"]))
                  }), 128)),
                  _createVNode(_component_prime_column, { header: "DNS" }, {
                    body: _withCtx((slot) => [
                      _createVNode(_component_DNSValidation, {
                        value: slot.data.dnsValidationValid
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]))
            : _createCommentVNode("", true),
          _createVNode(_component_prime_datatable, {
            value: _ctx.regCertsEntries,
            "responsive-layout": "scroll"
          }, {
            header: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("span", null, "Regional Certs Table", -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_prime_column, {
                header: "Region",
                field: "name"
              }),
              _createVNode(_component_prime_column, {
                header: "Status",
                field: "status"
              }, {
                body: _withCtx(({ data }) => [
                  _createVNode(_component_CertificateStatus, {
                    status: data.status
                  }, null, 8, ["status"])
                ]),
                _: 1
              }),
              _createVNode(_component_prime_column, {
                header: "Arn",
                field: "arn"
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.predecessor)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.getCertificatePath(_ctx.predecessor),
                  class: "certlink next"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "pi pi-angle-double-left" }, null, -1),
                    _createTextVNode(" Preceding Certificate ")
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3)),
            (_ctx.successor)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  to: _ctx.getCertificatePath(_ctx.successor),
                  class: "certlink next"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Succeeding Certificate "),
                    _createElementVNode("i", { class: "pi pi-angle-double-right" }, null, -1)
                  ])),
                  _: 1
                }, 8, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_4))
          ])
        ]))
  ], 64))
}