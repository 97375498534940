import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__content" }
const _hoisted_3 = { class: "header__menu" }

import { ref } from "vue";
    import Sidebar from "primevue/sidebar/Sidebar.vue";
    import Toast from "primevue/toast/Toast.vue";
    import TheNavigation from "@/components/TheNavigation.vue";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'TheHeader',
  setup(__props) {

    let visibleLeft = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_prime_button = _resolveComponent("prime-button")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_prime_button, {
          icon: "pi pi-bars",
          class: "header__menu-button p-button-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(visibleLeft) //@ts-ignore
 ? visibleLeft.value = true : visibleLeft = true))
        }),
        _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "header__headline" }, "Redirect Service Maintenance", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("a", {
          href: "https://confluence.basf.net/pages/viewpage.action?pageId=269389442",
          class: "header__documentation-link",
          title: "Documentation",
          target: "_blank"
        }, [
          _createElementVNode("i", { class: "pi pi-question" })
        ], -1))
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "header__logo-wrapper" }, [
        _createElementVNode("div", { class: "header__logo" })
      ], -1))
    ]),
    _createVNode(Sidebar, {
      visible: _unref(visibleLeft),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visibleLeft) ? (visibleLeft).value = $event : visibleLeft = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(TheNavigation, {
          onPageChanged: _cache[1] || (_cache[1] = ($event: any) => (_isRef(visibleLeft) //@ts-ignore
 ? visibleLeft.value = false : visibleLeft = false))
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(Toast, { position: "bottom-right" })
  ]))
}
}

})