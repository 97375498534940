
    import { defineComponent, PropType, toRefs } from "vue";
    import { DomainStatus } from "@/model/dto/certificate";

    export default defineComponent({
        name: "DomainStatus",
        props: {
            status: {
                type: String as PropType<DomainStatus>,
                required: true,
            },
        },
        setup(props) {
            return {
                statusIcon: `domainstatus--${props.status.toLowerCase()}`,
            };
        },
    });
