
    import { defineComponent, PropType, toRefs } from "vue";
    import { RegionalCertificateStatus } from "@/model/dto/certificate";

    export default defineComponent({
        name: "RegionalCertificateStatus",
        props: {
            status: {
                type: String as PropType<RegionalCertificateStatus>,
                required: true,
            },
        },
        setup(props) {
            return {
                statusIcon: `certstatus--${props.status.toLowerCase()}`,
            };
        },
    });
