import { FilterMatchMode } from "primevue/api";
import { CertificateStatus } from "@/model/dto/domain_v2";

export const CertStatusFilterConfig = {
    value: null,
    matchMode: FilterMatchMode.IN,
};

export function getCertStatusList(): CertificateStatus[] {
    return [
        CertificateStatus.INITIAL,
        CertificateStatus.PENDING,
        CertificateStatus.ISSUED,
        CertificateStatus.FAILED,
        CertificateStatus.NONE,
    ];
}
