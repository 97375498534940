import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { store } from "@/store";
import { Hub } from "@aws-amplify/core";
import { config$, ssoCallbackURL } from "@/config";
import { UserInfoDTO } from "@/model/dto/userinfo";
import axios, { axiosOptions } from "@/service/axios";

init().catch(console.error);

let initialized = false;

// unfortunately, the customState information is only available via event
// listener, so we need an early Hub#listen call
export const customState$ = customState();

export async function awsLogin(customState?: string): Promise<never> {
    await init();
    // won't resolve as location is changed; types are wrong within library
    return await (Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito,
        customState,
    }) as Promise<never>);
}

export async function clearSession() {
    await init();
    await Auth.signOut();
    initialized = false;
    await store.commit("authentication/setSession", null);
    await store.commit("authentication/setUserInfo", null);
}

export async function getCurrentSession() {
    await init();
    const session = await Auth.currentSession();
    await store.commit("authentication/setSession", session);
    await store.commit(
        "authentication/setUserInfo",
        session.getIdToken().getJwtToken(),
    );

    return session;
}

async function customState() {
    return await new Promise<string>((resolve, reject) => {
        const unsubscribe = Hub.listen(
            "auth",
            ({ payload: { event, data } }) => {
                // we always send a custom state, so this event must be triggered if
                // login was successful
                if (event === "customOAuthState") {
                    resolve(data as string);
                    unsubscribe();
                } else if (event === "customState_failure") {
                    reject(data);
                }
            },
        );
    });
}

async function init() {
    if (!initialized) {
        const config = await config$;
        console.log("Initializing auth object");
        initialized = true;

        Auth.configure({
            region: config.cognito.region,
            userPoolId: config.cognito.userPool.id,
            userPoolWebClientId: config.cognito.userPool.webClient.id,
            storage: localStorage,
            oauth: {
                domain: config.cognito.userPool.webClient.domain,
                scope: ["openid"],
                redirectSignIn: ssoCallbackURL,
                redirectSignOut: ssoCallbackURL,
                responseType: "code",
            },
        });

        // trigger store dispatch of current session
        await getCurrentSession();
    }
}
