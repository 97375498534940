import { DomainStatusDTO } from "@/model/dto/domain_v2";

export function getDomainsPath(entry: DomainStatusDTO): string {
    return `/domains/${entry.name}`;
}

export function getRulesPath(entry: DomainStatusDTO): string {
    return `${getDomainsPath(entry)}/rules`;
}

export function isWildcardDomain(entry: DomainStatusDTO): boolean {
    return entry.name.startsWith("*");
}

export function getCertificatePath(id: string): string {
    return `/certificates/${id}`;
}
