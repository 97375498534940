import { Filters } from "@/model/dto/domain_v2";
import { FilterMatchMode } from "primevue/api";

export const DefaultFilters: Filters = {
    page: 0,
    rows: 10,
    sortField: "name",
    sortOrder: 1,
    multiSortMeta: [],
};

export interface DNSStatus {
    value: boolean;
    label: string;
}

export const DNSStatusFilterConfig = {
    value: null,
    matchMode: FilterMatchMode.EQUALS,
};

export function getDNSStatusList(): DNSStatus[] {
    return [
        {
            value: true,
            label: "valid",
        },
        {
            value: false,
            label: "invalid",
        },
    ];
}
