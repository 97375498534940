
    import ProblemList from "@/components/ProblemList.vue";
    import { defineComponent, ref } from "vue";

    export default defineComponent({
        name: "TheProblemView",
        components: {
            ProblemList,
        },
    });
