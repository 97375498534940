import { Rule } from "./dto/rules";
import { RealmsBodyDTO } from "@/model/dto/realms";

export interface RedirectEntry {
    domain: string;
    country: string; // '*' for wildcard
    language: string; // '*' for wildcard
    operation?: string;
    rules: Rule[];
}

export enum ValidationStatus {
    UNKNOWN = "unknown",
    VALID = "valid",
    INVALID = "invalid",
}

export interface Validation {
    valid: ValidationStatus;
    checked: string;
}

export interface ValidationRecord {
    name: string;
    type: string;
    value: string;
}

export interface MassOnboardingEntry {
    domain: string;
    responsible: string;
    realms: RealmsBodyDTO[];
}

export interface MassRuleSet {
    domain: string;
    entry: RedirectEntry[];
}

export function redirectEntryKey(entry?: RedirectEntry) {
    return entry && `${entry.country}#${entry.language}`;
}

export function redirectEntryRuleKey(rule: Rule) {
    return `${rule.target}::${rule.matcher}`;
}
