
    import { Options, Vue } from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { ValidationRecord } from "@/model/redirect";

    @Options({
        name: "ValidationRecordDetails",
    })
    export default class ValidationRecordDetails extends Vue {
        @Prop({ required: true }) value!: ValidationRecord;
    }
