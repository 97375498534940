import { StoreOptions } from "vuex";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { UserInfoDTO } from "@/model/dto/userinfo";
import axios, { axiosOptions } from "@/service/axios";

const state = () => ({
    session: null as CognitoUserSession | null,
    usrInfo: null as UserInfoDTO | null,
});

export type State = ReturnType<typeof state>;

async function loadUserInfo(jwt: String): Promise<UserInfoDTO> {
    const dtoList = await axios.get<UserInfoDTO>(`/userinfo`, {
        ...(await axiosOptions({}, jwt)),
    });
    return dtoList.data;
}

const getters = {
    loggedIn(state: State) {
        return state.session !== null;
    },
    isAdmin(state: State) {
        return state.usrInfo?.admin;
    },
};

const mutations = {
    setSession(state: State, session: CognitoUserSession | null) {
        state.session = session;
    },
    setUserInfo(state: State, jwt: String) {
        //to avoid multipile calls
        if (!state.usrInfo) {
            loadUserInfo(jwt).then(value => (state.usrInfo = value));
        }
        if (!jwt) {
            state.usrInfo = null;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
} as StoreOptions<State>;
