import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass(["pi dns-status", [_ctx.iconClass, _ctx.colorClass]])
    }, null, 2),
    _createTextVNode(_toDisplayString(_ctx.value.valid) + " ", 1),
    (_ctx.value.checked)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode("at " + _toDisplayString(_ctx.timestamp), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}