const dateFormatter = new Intl.DateTimeFormat([...navigator.languages], {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
});

const countryFormatter = new (Intl as any).DisplayNames(
    [...navigator.languages],
    {
        type: "region",
    },
);

const languageFormatter = new (Intl as any).DisplayNames(
    [...navigator.languages],
    {
        type: "language",
    },
);

export function formattedDate(timestamp: string): string {
    return dateFormatter.format(Date.parse(timestamp));
}

export function countryName(code: string): string {
    if (code === "*") {
        return "any";
    }
    try {
        return countryFormatter.of(code);
    } catch (e: any) {
        console.log("unknown country code", code);
        return code;
    }
}

export function languageName(code: string): string {
    if (code === "*") {
        return "any";
    }
    try {
        return languageFormatter.of(code);
    } catch (e: any) {
        console.log("unknown language code", code);
        return code;
    }
}
