import { FilterMatchMode } from "primevue/api";
import { fetchResponsibles } from "@/service/responsibles";
import { Collection } from "@/model/dto/hateoas";
import { ResponsibleBodyDTO } from "@/model/dto/reponsible";
import { Filter } from "@/model/dto/domain_v2";

export const ResponsiblesFilterConfig: Filter = {
    value: null,
    matchMode: FilterMatchMode.IN,
};

export async function getResponsiblesList(): Promise<
    Collection<ResponsibleBodyDTO>
> {
    return await fetchResponsibles();
}
