import axios, { axiosOptions } from "@/service/axios";
import { ProblemDTO } from "@/model/dto/problem";

export async function fetchProblems(): Promise<ProblemDTO[]> {
    const dtoList = await axios.get<ProblemDTO[]>("/problems", {
        ...(await axiosOptions()),
    });
    return dtoList.data;
}

export async function fetchProblemsAsXls(): Promise<Blob> {
    const response = await axios.get("/problems/problems.xlsx", {
        ...(await axiosOptions({ responseType: "blob" })),
    });
    return response.data;
}
