import { createRouter, RouterOptions, createWebHistory } from "vue-router";
import DomainsView from "../views/OnboardingView.vue";
import ProblemsView from "../views/ProblemView.vue";
import CertificateView from "../views/CertificateView.vue";
import {
    clearSession,
    awsLogin,
    customState$,
    getCurrentSession,
} from "@/service/aws/session";

interface SSOLoginState {
    ref?: string;
}

const routes: RouterOptions["routes"] = [
    {
        path: "/",
        redirect: "/domains",
    },
    {
        path: "/domains",
        component: DomainsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/domains/:domain",
        component: () => import("../views/DomainView.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/domains/:domain/rules",
        component: () => import("../views/RulesView.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/certificates/:id",
        component: CertificateView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        component: DomainsView,
        async beforeEnter(to, from, next) {
            if (!to.query.hasOwnProperty("code")) {
                // Step 1: Redirect to SSO login
                await redirectToSSOLogin(to.query as SSOLoginState);
            } else {
                // Step 2: Code from SSO already handled during
                // @aws-amplify/auth#configure coll, redirect to ref and remove
                // query params
                const state = await parseSSOLoginResponse();
                next({ replace: true, path: state.ref || "/", query: {} });
            }
        },
    },
    {
        path: "/logout",
        component: DomainsView,
        async beforeEnter(to, from, next) {
            await clearSession();
            next("/");
        },
    },
    {
        path: "/problems",
        component: ProblemsView,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            await getCurrentSession();
            next();
        } catch (e: any) {
            next({
                path: "/login",
                query: { ref: to.fullPath },
            });
        }
    } else {
        next();
    }
});

export default router;

async function redirectToSSOLogin(state: SSOLoginState): Promise<never> {
    return await awsLogin(btoa(JSON.stringify(state)));
}

async function parseSSOLoginResponse(): Promise<SSOLoginState> {
    return JSON.parse(atob(await customState$)) as SSOLoginState;
}
