import axios, { axiosOptions } from "@/service/axios";
import { Paged } from "@/model/dto/hateoas";
import { ResponsibleBodyDTO } from "@/model/dto/reponsible";

export async function fetchResponsibles(): Promise<Paged<ResponsibleBodyDTO>> {
    const dtoList = await axios.get<Paged<ResponsibleBodyDTO>>(
        `/responsibles`,
        await axiosOptions(),
    );

    return dtoList.data;
}
