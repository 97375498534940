import axios from "axios";

export enum Environment {
    DEVELOPMENT = "development",
    TEST = "test",
    PRODUCTION = "production",
}

export interface Config {
    apiURL: string;
    cognito: {
        region: string;
        userPool: {
            id: string;
            webClient: {
                id: string;
                domain: string;
            };
        };
    };
}

export const env = process.env.NODE_ENV as any as Environment;
export const ssoCallbackURL =
    env === Environment.DEVELOPMENT
        ? process.env.VUE_APP_SSO_CALLBACK_URL
        : location.origin;
export const config$ = fetchConfig();

async function fetchConfig(): Promise<Config> {
    try {
        return (await axios.get<Config>("/config.json")).data;
    } catch (e: any) {
        console.warn(
            "No config.json found. Using environment variables instead.",
        );
        return {
            apiURL: process.env.VUE_APP_API_URL!,
            cognito: {
                region: process.env.VUE_APP_COGNITO_REGION!,
                userPool: {
                    id: process.env.VUE_APP_USER_POOL_ID!,
                    webClient: {
                        id: process.env.VUE_APP_USER_POOL_CLIENT_ID!,
                        domain: process.env.VUE_APP_USER_POOL_CLIENT_DOMAIN!,
                    },
                },
            },
        };
    }
}
