
    import { CertificateStatus, DomainStatusDTO, Filters } from "@/model/dto/domain_v2";
    import { deleteEntity, fetchDomains, updateDomain } from "@/service/domains";
    import DomainAddDialog from "@/components/DomainAddDialog.vue";
    import { FilterMatchMode } from "primevue/api";
    import { computed, defineComponent, onMounted, ref } from "vue";
    import { ComputedRef, Ref } from "@vue/reactivity";
    import { useConfirm } from "primevue/useconfirm";
    import { useToast } from "primevue/usetoast";
    import {
        CertStatusFilterConfig,
        DefaultFilters,
        DNSStatus,
        DNSStatusFilterConfig,
        flattenRealms,
        getCertStatusList,
        getDNSStatusList,
        getDomainsPath,
        getResponsiblesList,
        getRulesPath,
        isWildcardDomain,
        ResponsiblesFilterConfig,
    } from "@/composables/DomainStatusList";
    import { ResponsibleBodyDTO } from "@/model/dto/reponsible";
    import { formattedDate } from "@/util/i18n";
    import MultiSelect from "primevue/multiselect";
    import Realms from "@/components/Realms.vue";
    import _ from "lodash";
    import { fetchRealms } from "@/service/realms";
    import { RealmsBodyDTO } from "@/model/dto/realms";

    import CertificateStatusVue from "@/components/CertificateStatus.vue";

    // Defined outside of component to persist on back navigation
    const currentFilters: Filters = Object.assign(
        {
            filters: {
                responsible: ResponsiblesFilterConfig,
                name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                certStatus: CertStatusFilterConfig,
                dnsValid: DNSStatusFilterConfig,
                realms: { value: null, matchMode: FilterMatchMode.IN },
            },
        },
        DefaultFilters,
    );

    export default defineComponent({
        name: "DomainsStatusList",
        components: { Realms, DomainAddDialog, CertificateStatus: CertificateStatusVue },
        setup: function () {
            const realmsRefs: Map<Number, typeof Realms> = new Map();
            const domains: Ref<DomainStatusDTO[]> = ref([]);
            const loading: Ref<boolean> = ref(true);
            const canAdd: Ref<boolean> = ref(false);
            const canMassLoad: Ref<boolean> = ref(false);
            const originalRows: Ref<Map<number, DomainStatusDTO>> = ref(new Map());
            const editing: Ref<Map<number, DomainStatusDTO>> = ref(new Map());
            const editingRows: Ref<DomainStatusDTO[]> = computed(() =>
                Array.from(editing.value.values()),
            );

            const totalRecords: Ref<number> = ref(0);
            const responsibles: Ref<ResponsibleBodyDTO[]> = ref([]);
            const realmsList: Ref<RealmsBodyDTO[]> = ref([]);

            const certStatusList: Ref<CertificateStatus[]> = ref(getCertStatusList());
            const dnsStatusList: Ref<DNSStatus[]> = ref(getDNSStatusList());
            const addDialog: Ref<boolean> = ref(false);

            const confirm = useConfirm();
            const toast = useToast();
            const loadingError: Ref<boolean> = ref(false);

            // loading
            onMounted(() => reloadData());

            const reloadData = async () => {
                await Promise.all([loadDomains(), loadResponsibles(), loadRealms()]);
            };

            const loadDomains = async () => {
                try {
                    loading.value = true;

                    let page = await fetchDomains(currentFilters);

                    totalRecords.value = page.page.totalElements;

                    if (page._embedded?.content) {
                        domains.value = page._embedded.content;
                    } else {
                        domains.value = [];
                    }
                    canAdd.value = page._links.onboard !== undefined;
                    canMassLoad.value = page._links.massload !== undefined;

                    originalRows.value.clear();
                } catch (e: any) {
                    loadingError.value = true;
                }
                loading.value = false;
            };

            const loadResponsibles = async () => {
                const result = await getResponsiblesList();
                responsibles.value = result._embedded?.content;
                if (
                    currentFilters.filters?.responsible?.value &&
                    Array.isArray(currentFilters.filters?.responsible?.value)
                ) {
                    const oldValue = currentFilters.filters.responsible.value as string[];
                    currentFilters.filters.responsible.value = oldValue.filter(responsible =>
                        responsibles.value?.includes({ responsible: responsible }),
                    );
                }
            };

            const loadRealms = async () => {
                const result = await fetchRealms();
                realmsList.value = result._embedded?.content;
                if (
                    currentFilters.filters?.realms?.value &&
                    Array.isArray(currentFilters.filters?.realms?.value)
                ) {
                    const oldValue = currentFilters.filters.realms.value as string[];
                    currentFilters.filters.realms.value = oldValue.filter(realm =>
                        realmsList.value?.includes({ name: realm }),
                    );
                }
            };
            // table features
            const sorting: ComputedRef<boolean> = computed(() => editingRows.value.length === 0);

            const certStatusIcon = (status: DomainStatusDTO) => {
                return "domainstatus--" + status.currentCertStatus.toLowerCase();
            };

            const clearFilters = async () => {
                if (currentFilters.filters) {
                    currentFilters.filters.name.value = null;
                    currentFilters.filters.responsible.value = null;
                    currentFilters.filters.certStatus.value = null;
                    currentFilters.filters.realms.value = null;
                    currentFilters.filters.dnsValid.value = null;
                }
                await loadDomains();
            };

            const onRowEditInit = (domain: DomainStatusDTO, index: number) => {
                const oldValue = _.cloneDeep(domains.value[index]);
                originalRows.value.set(index, oldValue);
                editing.value.set(index, domains.value[index]);
            };

            const onRowEditSave = async (domain: DomainStatusDTO, index: number) => {
                loading.value = true;
                try {
                    realmsRefs.get(index)?.newRealm();
                    let newValue = await updateDomain(domain);
                    if (newValue) {
                        domains.value[index] = newValue;
                    }
                } catch (e: any) {
                    console.error("Error updating domain: ", e);
                    toast.add({
                        severity: "error",
                        summary: `Could not update ${domain.name}`,
                        detail: e.response?.data || e,
                        life: 10000,
                    });
                }
                await Promise.all([loadResponsibles(), loadRealms()]);
                loading.value = false;
                originalRows.value.delete(index);
                editing.value.delete(index);
            };

            const onRowEditCancel = (domain: DomainStatusDTO, index: number) => {
                let oldValue = originalRows.value.get(index);
                if (oldValue) {
                    domains.value[index] = oldValue;
                }
                originalRows.value.delete(index);
                editing.value.delete(index);
            };

            const confirmDelete = async (domain: DomainStatusDTO) => {
                const domainName = domain.name;
                confirm.require({
                    message: `Are you sure you want to delete ${domainName}?\nAll rules maintained for ${domainName} will be deleted also.`,
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    acceptIcon: "pi pi-trash",
                    acceptClass: "p-button-danger",
                    accept: async () => {
                        let error = false;
                        try {
                            await deleteEntity(domain);
                        } catch (e: any) {
                            console.error("Error deleting domain: ", e);
                            error = true;
                            toast.add({
                                severity: "error",
                                summary: `Could not delete ${domainName}`,
                                detail: e.response?.data || e,
                                life: 10000,
                            });
                        }
                        if (!error) {
                            toast.add({
                                severity: "success",
                                summary: "Deleted",
                                detail: `Deleted ${domainName} and its rules.`,
                                life: 3000,
                            });
                            await loadDomains();
                        }
                    },
                });
            };

            const onPage = async (event: Filters) => {
                currentFilters.page = event.page;
                currentFilters.rows = event.rows;
                await loadDomains();
            };
            const onSort = async (event: Filters) => {
                currentFilters.sortField = event.sortField;
                currentFilters.sortOrder = event.sortOrder;
                await loadDomains();
            };
            const onFilter = async (event: Filters) => {
                currentFilters.filters = event.filters;
                await loadDomains();
            };

            const responsiblesFilter: Ref<MultiSelect | null> = ref(null);
            const onResponsibleChange = (filterCallback: Function) => {
                //@ts-ignore "hide" is documented but not part of the type. Thanks, PrimeVue
                responsiblesFilter.value?.hide();
                filterCallback();
            };

            const certStatusFilter: Ref<MultiSelect | null> = ref(null);
            const onCertStatusChange = (filterCallback: Function) => {
                //@ts-ignore "hide" is documented but not part of the type. Thanks, PrimeVue
                certStatusFilter.value?.hide();
                filterCallback();
            };

            const dnsStatusFilter: Ref<MultiSelect | null> = ref(null);
            const onDNSStatusChange = (filterCallback: Function) => {
                //@ts-ignore "hide" is documented but not part of the type. Thanks, PrimeVue
                dnsStatusFilter.value?.hide();
                filterCallback();
            };

            const realmsFilter: Ref<MultiSelect | null> = ref(null);
            const onRealmChange = (filterCallback: Function) => {
                //@ts-ignore "hide" is documented but not part of the type. Thanks, PrimeVue
                realmsFilter.value?.hide();
                filterCallback();
            };
            const setRealmsRef = (index: number, realms: typeof Realms) => {
                realmsRefs.set(index, realms);
            };
            return {
                setRealmsRef,
                reloadData,
                domains,
                canAdd,
                canMassLoad,
                editingRows,
                totalRecords,
                loading,
                loadDomains,
                currentFilters,
                clearFilters,
                responsibles,
                certStatusList,
                dnsStatusList,
                addDialog,
                sorting,
                certStatusIcon,
                formattedDate,
                getDomainsPath,
                getRulesPath,
                isWildcardDomain,
                onRowEditInit,
                onRowEditSave,
                onRowEditCancel,
                confirmDelete,
                onPage,
                onSort,
                onFilter,
                onResponsibleChange,
                responsiblesFilter,
                certStatusFilter,
                dnsStatusFilter,
                realmsFilter,
                onCertStatusChange,
                onDNSStatusChange,
                onRealmChange,
                loadingError,
                flattenRealms,
                realmsList,
            };
        },
    });
