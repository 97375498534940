import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Name"),
            _createElementVNode("th", null, "Type"),
            _createElementVNode("th", null, "Value")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, _toDisplayString(_ctx.value.name), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.value.type), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.value.value), 1)
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, "Validation record not yet available. Please check back later"))
}