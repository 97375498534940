import axios, { axiosOptions } from "@/service/axios";
import { Collection } from "@/model/dto/hateoas";
import { RealmsBodyDTO } from "@/model/dto/realms";

export async function fetchRealms(): Promise<Collection<RealmsBodyDTO>> {
    const dtoList = await axios.get<Collection<RealmsBodyDTO>>(
        `/realms`,
        await axiosOptions(),
    );

    return dtoList.data;
}
