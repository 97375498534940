import { Collection, Entity } from "@/model/dto/hateoas";
import { Validation, ValidationRecord } from "@/model/redirect";
import { RealmsBodyDTO } from "@/model/dto/realms";

export interface Filter {
    value: string[] | string | null;
    matchMode?: string;
}

export interface Filters {
    page: number;
    rows: number;

    filters?: Record<string, Filter>;

    sortField?: string;
    sortOrder?: number;
    multiSortMeta?: any[];
}

export enum CertificateStatus {
    INITIAL = "initial",
    PENDING = "pending",
    ISSUED = "issued",
    FAILED = "failed",
    NONE = "none",
}

export enum AcceleratorStatus {
    INITIAL = "initial",
    PENDING = "pending",
    ACTIVE = "active",
    ERROR = "error",
}

export interface ApiKeyDTO extends Entity {
    apiKey: string;
    created: string;
}

export interface DomainStatusDTO extends Entity, UpdateRequestBodyDTO {
    name: string;
    created: string;
    currentCertStatus: CertificateStatus;
    currentCertId: string;
    futureCertStatus: CertificateStatus;
    futureCertId: string;
    validationRecord: ValidationRecord;
    validationRecordValid: Validation;
    gaStatus: AcceleratorStatus;
    ip: string;
    dnsValid: boolean;
    dnsGaValid: Validation;
    realms: RealmsBodyDTO[];
    apiKeys: Collection<ApiKeyDTO>;
}

export interface OnboardingRequestBodyDTO {
    responsible: string;
    realms: RealmsBodyDTO[];
    external: boolean;
}

export interface UpdateRequestBodyDTO {
    responsible: string;
    version: string;
}
