
    import { Collection } from "@/model/dto/hateoas";
    import { ApiKeyDTO } from "@/model/dto/domain_v2";
    import { useConfirm } from "primevue/useconfirm";
    import { useToast } from "primevue/usetoast";
    import { addApiKey, deleteEntity } from "@/service/domains";
    import { defineComponent, PropType } from "vue";
    import { formattedDate } from "@/util/i18n";

    export default defineComponent({
        name: "ApiKeys",
        props: {
            value: {
                type: Object as PropType<Collection<ApiKeyDTO>>,
                required: true,
            },
            editable: {
                type: Boolean,
                default: true,
            },
        },
        emits: ["reload"],
        setup(props, { emit }) {
            const confirm = useConfirm();
            const toast = useToast();

            const addKey = async () => {
                addApiKey(props.value);
                emit("reload");
            };
            const deleteKey = async (key: ApiKeyDTO) => {
                const apiKey = key.apiKey;
                confirm.require({
                    message: `Are you sure you want to delete ${apiKey}?`,
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    acceptIcon: "pi pi-trash",
                    acceptClass: "p-button-danger",
                    accept: async () => {
                        let error = false;
                        try {
                            await deleteEntity(key);
                        } catch (e: any) {
                            console.error("Error deleting domain: ", e);
                            error = true;
                            toast.add({
                                severity: "error",
                                summary: `Could not delete ${apiKey}`,
                                detail: e.response?.data || e,
                                life: 10000,
                            });
                        }
                        if (!error) {
                            emit("reload");
                            toast.add({
                                severity: "success",
                                summary: "Deleted",
                                detail: `Deleted ${apiKey} and its rules.`,
                                life: 3000,
                            });
                        }
                    },
                });
            };
            return {
                addKey,
                deleteKey,
                formattedDate,
            };
        },
    });
