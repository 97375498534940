
    import { Options, Vue } from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import download from "@/util/binary";

    @Options({
        name: "BulkDomainAndRulesUpload",
    })
    export default class BulkDomainAndRulesUpload extends Vue {
        private success: boolean = false;
        private error: boolean = false;
        private warning: boolean = false;
        private loading: boolean = false;
        private message: string = "";
        private bulkImportResult: Blob | undefined;

        @Prop({ required: true }) readonly consumer!: Function;
        @Prop() readonly label!: string;
        @Prop({ default: false }) readonly disabled: boolean = false;

        onSelect() {
            this.success = false;
            this.error = false;
            this.warning = false;
        }

        get buttonLabel(): string {
            return this.label && this.label.length > 0 ? this.label : "Choose";
        }

        async xlsUploader(evt: { files: File[] | null[] }) {
            this.loading = true;
            const xlsFile: File | null = evt.files[0];

            if (this.consumer !== null && xlsFile !== null) {
                try {
                    this.bulkImportResult = await this.consumer(xlsFile);

                    if (this.bulkImportResult && this.bulkImportResult.size > 0) {
                        this.success = true;
                        download(this.bulkImportResult, "bulk-import-result.xlsx");
                    } else {
                        this.warning = true;
                    }
                    this.$emit("onboarding:loaded");
                } catch (e: any) {
                    console.error("Error processing bulk upload: " + e.response);
                    this.message = e.response.data;
                    this.error = true;
                } finally {
                    this.loading = false;
                }
            }

            evt.files.pop();
        }
    }
