
    import { defineComponent, onMounted, ref, watch } from "vue";
    import { Ref } from "@vue/reactivity";
    import { CertificateDetailDTO } from "@/model/dto/certificate";
    import getIDFromPath from "@/util/getIDFromPath";
    import { fetchCertificate } from "@/service/certificates";
    import {
        CertificateViewModel,
        getDomainEntries,
        getRegionCertEntries,
    } from "@/composables/CertificateView/CertDataMappings";
    import { default as RegionalCertificateStatusComponent } from "@/components/RegionalCertificateStatus.vue";
    import { default as DomainStatusComponent } from "@/components/DomainStatus.vue";
    import { getCertificatePath } from "@/composables/DomainStatusList";
    import DNSValidation from "@/components/DNSValidation.vue";

    export default defineComponent({
        name: "CertificateView",
        components: {
            CertificateStatus: RegionalCertificateStatusComponent,
            DomainStatus: DomainStatusComponent,
            DNSValidation,
        },
        setup() {
            let certificate: CertificateDetailDTO | null = null;
            const domainEntries: Ref<any> = ref([]);
            const regions: Ref<any> = ref([]);
            const regCertsEntries: Ref<any> = ref([]);
            const certID: Ref<number> = getIDFromPath();

            const predecessor: Ref<string | null> = ref(null);
            const successor: Ref<string | null> = ref(null);
            const loadingError: Ref<boolean> = ref(false);

            const loadCertificate = async () => {
                try {
                    certificate = await fetchCertificate(certID.value);

                    const viewModel: CertificateViewModel = getDomainEntries(certificate);

                    regions.value = viewModel.regions;
                    domainEntries.value = viewModel.domainEntries;
                    regCertsEntries.value = getRegionCertEntries(certificate);

                    predecessor.value = certificate.predecessor;
                    successor.value = certificate.successor;
                } catch (e: any) {
                    loadingError.value = true;
                }
            };

            onMounted(loadCertificate);
            watch(certID, async () => {
                await loadCertificate();
            });

            return {
                domainEntries,
                regCertsEntries,
                regions,
                predecessor,
                successor,
                loadingError,
                getCertificatePath,
            };
        },
    });
