
    import { Options, Vue } from "vue-class-component";

    import { Prop } from "vue-property-decorator";
    import { CertificateStatus } from "@/model/dto/domain_v2";

    @Options({
        name: "CertificateStatus",
    })
    export default class CertificateStatusVue extends Vue {
        @Prop({ required: true }) status!: CertificateStatus;

        get iconClass(): string {
            return `certificate-status--${this.status.toLowerCase()}`;
        }
    }
