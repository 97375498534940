
    import ConfirmDialog from "primevue/confirmdialog/ConfirmDialog.vue";
    import TheHeader from "@/components/TheHeader.vue";

    export default {
        components: {
            TheHeader,
            ConfirmDialog,
        },
    };
