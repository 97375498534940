import { CertificateDetailDTO } from "@/model/dto/certificate";

export interface CertificateViewModel {
    domainEntries: any;
    regions: any;
}

export interface RegionCertEntry {
    name: string;
    status: string;
    arn: string;
}

export function getDomainEntries(
    certificate: CertificateDetailDTO,
): CertificateViewModel {
    const domainNames = Object.keys(certificate.domains);
    const domains = Object.values(certificate.domains);
    const viewModel: CertificateViewModel = {
        domainEntries: [],
        regions: [],
    };

    for (let i = 0; i < domainNames.length; i++) {
        const tempRegions: any = Object.keys(domains[i].regions);
        const tempStatus: any = Object.values(domains[i].regions);
        let mapped: any = [];
        for (let j = 0; j < tempRegions.length; j++) {
            if (!viewModel.regions.some((a: string) => a === tempRegions[j])) {
                viewModel.regions.push(tempRegions[j]);
            }
            mapped = { ...mapped, [tempRegions[j]]: tempStatus[j].status };
        }
        viewModel.domainEntries.push({
            name: domainNames[i],
            dnsValidationValid: domains[i].dnsValidationValid,
            ...mapped,
        });
    }

    return viewModel;
}

export function getRegionCertEntries(
    certificate: CertificateDetailDTO,
): RegionCertEntry[] {
    const regCertsEntries: RegionCertEntry[] = [];

    const regCerNames = Object.keys(certificate.regionalCerts);
    const values = Object.values(certificate.regionalCerts);

    for (let i = 0; i < regCerNames.length; i++) {
        regCertsEntries.push({
            name: regCerNames[i],
            status: values[i].status,
            arn: values[i].arn,
        });
    }

    return regCertsEntries;
}
