import axios, { AxiosRequestConfig } from "axios";
import { config$ } from "@/config";
import { getCurrentSession } from "@/service/aws/session";

const instance = axios.create();
//to avoid circles jwt can be used directly without loading
export async function axiosOptions(
    merge: AxiosRequestConfig = {},
    jwtToken?: String,
): Promise<AxiosRequestConfig> {
    const config = await config$;
    try {
        let toUseToken;
        if (jwtToken) {
            toUseToken = jwtToken;
        } else {
            const session = await getCurrentSession();
            toUseToken = session.getIdToken().getJwtToken();
        }
        return {
            ...merge,
            baseURL: config.apiURL,
            headers: {
                ...(merge.headers || {}),
                Authorization: `Bearer ${toUseToken}`,
            },
        };
    } catch (e: any) {
        return {
            ...merge,
            baseURL: config.apiURL,
        };
    }
}

export default instance;
