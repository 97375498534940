
    import { Options, Vue } from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { AcceleratorStatus } from "@/model/dto/domain_v2";

    @Options({
        name: "AcceleratorStatus",
    })
    export default class AcceleratorStatusVue extends Vue {
        @Prop({ required: true }) status!: AcceleratorStatus;

        get iconClass(): string {
            return `accelerator-status--${this.status.toLowerCase()}`;
        }
    }
