
    import DomainsStatusList from "@/components/DomainsStatusList.vue";
    import { defineComponent, ref } from "vue";

    export default defineComponent({
        name: "TheOnboardingView",
        components: {
            DomainsStatusList,
        },
    });
