
    import { Options, Vue } from "vue-class-component";
    import DomainOnboarding from "@/components/DomainOnboarding.vue";
    import { Emit, Model, Prop } from "vue-property-decorator";
    import { postMassImport } from "@/service/domains";
    import BulkDomainAndRulesUpload from "@/components/BulkDomainAndRulesUpload.vue";

    @Options({
        name: "DomainAddDialog",
        components: {
            BulkDomainAndRulesUpload,
            DomainOnboarding,
        },
    })
    export default class DomainAddDialog extends Vue {
        private importConsumer: Function | null = null;
        @Prop({ required: true }) canMassLoad!: boolean;
        @Model("show", { default: false }) visible!: boolean;

        mounted() {
            this.importConsumer = postMassImport;
        }

        @Emit("onboarding:updated")
        handleNewDomain(): void {
            this.hide();
        }

        hide(): void {
            this.visible = false;
        }
    }
