
    import { Options, Vue } from "vue-class-component";
    import { mapGetters } from "vuex";

    //TODO move loggedIn check into components
    @Options({
        name: "TheNavigation",
        computed: mapGetters("authentication", ["loggedIn", "isAdmin"]),
    })
    export default class TheNavigation extends Vue {}
