import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "domainpanel" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "p-multiselect-representative-option" }
const _hoisted_5 = {
  key: 0,
  class: "pi pi-check dns-status dns-status--valid"
}
const _hoisted_6 = {
  key: 1,
  class: "pi pi-times dns-status dns-status--invalid"
}
const _hoisted_7 = {
  key: 0,
  class: "pi pi-check dns-status dns-status--valid"
}
const _hoisted_8 = {
  key: 1,
  class: "pi pi-times dns-status dns-status--invalid"
}
const _hoisted_9 = {
  key: 0,
  class: "pi pi-check dns-status dns-status--valid"
}
const _hoisted_10 = {
  key: 1,
  class: "pi pi-times dns-status dns-status--invalid"
}
const _hoisted_11 = { class: "p-multiselect-representative-option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_message = _resolveComponent("prime-message")!
  const _component_prime_inputtext = _resolveComponent("prime-inputtext")!
  const _component_prime_column = _resolveComponent("prime-column")!
  const _component_prime_multi_select = _resolveComponent("prime-multi-select")!
  const _component_Realms = _resolveComponent("Realms")!
  const _component_CertificateStatus = _resolveComponent("CertificateStatus")!
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_prime_datatable = _resolveComponent("prime-datatable")!
  const _component_DomainAddDialog = _resolveComponent("DomainAddDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loadingError)
      ? (_openBlock(), _createBlock(_component_prime_message, {
          key: 0,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" Error loading domains, please try again ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_prime_datatable, {
          key: 1,
          filters: _ctx.currentFilters.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentFilters.filters) = $event)),
          "filter-display": "row",
          value: _ctx.domains,
          "editing-rows": _ctx.editingRows,
          loading: _ctx.loading,
          "edit-mode": "row",
          "data-key": "name",
          scrollable: true,
          "scroll-height": "flex",
          class: "domaintable",
          paginator: true,
          lazy: true,
          "total-records": _ctx.totalRecords,
          rows: 10,
          "rows-per-page-options": [10, 50, 100],
          "responsive-layout": "scroll",
          "sort-field": "name",
          "sort-order": 1,
          "current-page-report-template": "Showing {first} to {last} of {totalRecords}",
          onPage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onPage($event))),
          onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSort($event))),
          onFilter: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onFilter($event)))
        }, {
          empty: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" No onboarded domains found.")
          ])),
          loading: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Loading domains. Please wait.")
          ])),
          default: _withCtx(() => [
            _createVNode(_component_prime_column, {
              field: "name",
              header: "Domain",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("span", null, _toDisplayString(data.name), 1)
              ]),
              editor: _withCtx(({ data }) => [
                _createElementVNode("span", null, _toDisplayString(data.name), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_prime_inputtext, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  class: "p-column-filter",
                  placeholder: `Search by domain`,
                  onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              header: "Responsible",
              field: "responsible",
              sortable: true,
              "show-filter-match-modes": false,
              "show-filter-menu": false
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("span", null, _toDisplayString(data.responsible), 1)
              ]),
              editor: _withCtx(({ data }) => [
                (data._links.update_responsible)
                  ? (_openBlock(), _createBlock(_component_prime_inputtext, {
                      key: 0,
                      modelValue: data.responsible,
                      "onUpdate:modelValue": ($event: any) => ((data.responsible) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.responsible), 1))
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_prime_multi_select, {
                  ref: _ctx.responsiblesFilter,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.responsibles!,
                  "option-label": "responsible",
                  "option-value": "responsible",
                  filter: true,
                  "show-toggle-all": false,
                  "selection-limit": 20,
                  placeholder: "Any",
                  class: "p-column-filter",
                  onChange: ($event: any) => (_ctx.onResponsibleChange(filterCallback))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              header: "Realms",
              field: "realms",
              sortable: false,
              "show-filter-match-modes": false,
              "show-filter-menu": false
            }, {
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_prime_multi_select, {
                  ref: _ctx.realmsFilter,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.realmsList,
                  filter: true,
                  "show-toggle-all": false,
                  "option-label": "name",
                  "option-value": "name",
                  placeholder: "Any",
                  class: "p-column-filter",
                  onChange: ($event: any) => (_ctx.onRealmChange(filterCallback))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              body: _withCtx(({ data }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.flattenRealms(data.realms)), 1)
              ]),
              editor: _withCtx(({ index, data }) => [
                (data._links.update_realms)
                  ? (_openBlock(), _createBlock(_component_Realms, {
                      key: 0,
                      ref: ref => _ctx.setRealmsRef(index, ref),
                      modelValue: data.realms,
                      "onUpdate:modelValue": ($event: any) => ((data.realms) = $event),
                      placeholder: "Add a realm"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.flattenRealms(data.realms)), 1))
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              field: "created",
              header: "Created",
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.formattedDate(data.created)), 1)
              ]),
              editor: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.formattedDate(data.created)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              "filter-field": "dnsValid",
              class: "dns-status-col",
              "show-filter-match-modes": false,
              "show-filter-menu": false
            }, {
              header: _withCtx(() => [
                _withDirectives((_openBlock(), _createElementBlock("span", null, _cache[11] || (_cache[11] = [
                  _createTextVNode("DNS")
                ]))), [
                  [_directive_tooltip, 
                            'Checks if the domain IP points to the RS and the certification data is correct'
                        ]
                ])
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_prime_multi_select, {
                  ref: _ctx.dnsStatusFilter,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.dnsStatusList,
                  "selection-limit": 1,
                  "option-label": "label",
                  "option-value": "value",
                  placeholder: "Any",
                  class: "p-column-filter",
                  "show-toggle-all": false,
                  onChange: ($event: any) => (_ctx.onDNSStatusChange(filterCallback))
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_4, [
                      (option.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                        : (_openBlock(), _createElementBlock("span", _hoisted_6)),
                      _createElementVNode("span", null, _toDisplayString(option.label), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              body: _withCtx(({ data }) => [
                (data.dnsValid)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8))
              ]),
              editor: _withCtx(({ data }) => [
                (data.dnsValid)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10))
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              "filter-field": "certStatus",
              "sort-field": "certStatus",
              header: "Certificate",
              sortable: true,
              "show-filter-match-modes": false,
              "show-filter-menu": false
            }, {
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_prime_multi_select, {
                  ref: _ctx.certStatusFilter,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.certStatusList,
                  placeholder: "Any",
                  class: "p-column-filter",
                  "show-toggle-all": false,
                  onChange: ($event: any) => (_ctx.onCertStatusChange(filterCallback))
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_CertificateStatus, { status: option }, null, 8, ["status"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              body: _withCtx(({ data }) => [
                _createVNode(_component_CertificateStatus, {
                  status: data.currentCertStatus
                }, null, 8, ["status"])
              ]),
              editor: _withCtx(({ data }) => [
                _createVNode(_component_CertificateStatus, {
                  status: data.currentCertStatus
                }, null, 8, ["status"])
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              exportable: false,
              class: "row-actions"
            }, {
              header: _withCtx(() => [
                _createVNode(_component_prime_button, {
                  title: "Reload domains",
                  icon: "pi pi-refresh",
                  class: "p-button-rounded",
                  onClick: _ctx.loadDomains
                }, null, 8, ["onClick"]),
                _createVNode(_component_prime_button, {
                  disabled: !_ctx.canAdd,
                  title: "Add domain(s)",
                  icon: "pi pi-plus",
                  class: "p-button-rounded",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addDialog = true))
                }, null, 8, ["disabled"]),
                _createVNode(_component_prime_button, {
                  icon: "pi pi-filter-slash",
                  title: "Clear all filters",
                  class: "p-button-rounded",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilters()))
                })
              ]),
              body: _withCtx(({ index, data }) => [
                _createVNode(_component_router_link, {
                  to: _ctx.getDomainsPath(data),
                  class: "link-no-decoration"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_prime_button, {
                      title: "Info",
                      icon: "pi pi-info",
                      class: "p-button-rounded p-button-success"
                    })
                  ]),
                  _: 2
                }, 1032, ["to"]),
                _createVNode(_component_router_link, {
                  to: _ctx.getRulesPath(data),
                  class: "link-no-decoration",
                  disabled: _ctx.isWildcardDomain(data)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_prime_button, {
                      title: "Rules",
                      icon: "pi pi-directions",
                      class: "p-button-rounded p-button-success"
                    })
                  ]),
                  _: 2
                }, 1032, ["to", "disabled"]),
                _createVNode(_component_prime_button, {
                  disabled: !data._links.update_responsibles && !data._links.update_realms,
                  title: "Edit",
                  icon: "pi pi-pencil",
                  class: "p-button-rounded p-button-success",
                  onClick: ($event: any) => (_ctx.onRowEditInit(data, index))
                }, null, 8, ["disabled", "onClick"]),
                _createVNode(_component_prime_button, {
                  disabled: !data._links.delete,
                  title: "Off-Board",
                  icon: "pi pi-trash",
                  class: "p-button-rounded p-button-success",
                  onClick: ($event: any) => (_ctx.confirmDelete(data))
                }, null, 8, ["disabled", "onClick"])
              ]),
              editor: _withCtx(({ index, data }) => [
                _createVNode(_component_prime_button, {
                  title: "Save",
                  icon: "pi pi-check",
                  class: "p-button-rounded p-button-success",
                  onClick: ($event: any) => (_ctx.onRowEditSave(data, index))
                }, null, 8, ["onClick"]),
                _createVNode(_component_prime_button, {
                  title: "Cancel",
                  icon: "pi pi-times",
                  class: "p-button-rounded p-button-success",
                  onClick: ($event: any) => (_ctx.onRowEditCancel(data, index))
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["filters", "value", "editing-rows", "loading", "total-records"])),
    (!_ctx.loadingError)
      ? (_openBlock(), _createBlock(_component_DomainAddDialog, {
          key: 2,
          show: _ctx.addDialog,
          "onUpdate:show": [
            _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addDialog) = $event)),
            _cache[7] || (_cache[7] = f => (_ctx.addDialog = f))
          ],
          "can-mass-load": _ctx.canMassLoad,
          "onOnboarding:updated": _ctx.reloadData
        }, null, 8, ["show", "can-mass-load", "onOnboarding:updated"]))
      : _createCommentVNode("", true)
  ]))
}