
    import { Options, Vue } from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { Validation, ValidationStatus } from "@/model/redirect";
    import { formattedDate } from "@/util/i18n";

    @Options({
        name: "DNSValidation",
    })
    export default class DNSValidation extends Vue {
        @Prop({ required: true }) value!: Validation;

        get iconClass(): string {
            switch (this.value.valid) {
                case ValidationStatus.VALID:
                    return "pi-check";
                case ValidationStatus.INVALID:
                    return "pi-times";
                default:
                    return "pi-question";
            }
        }

        get colorClass(): string {
            return `dns-status--${this.value.valid.toLowerCase()}`;
        }

        get timestamp(): string {
            return formattedDate(this.value.checked);
        }
    }
