import axios, { axiosOptions } from "@/service/axios";
import { Paged } from "@/model/dto/hateoas";
import {
    CertificateDetailDTO,
    CertificateEntryDTO,
} from "@/model/dto/certificate";

export async function fetchCertificates(): Promise<Paged<CertificateEntryDTO>> {
    const dtoList = await axios.get<Paged<CertificateEntryDTO>>(
        `/certificates`,
        await axiosOptions(),
    );

    return dtoList.data;
}

export async function fetchCertificate(
    id: number,
): Promise<CertificateDetailDTO> {
    const dtoList = await axios.get<CertificateDetailDTO>(
        `/certificates/${id}`,
        await axiosOptions(),
    );

    return dtoList.data;
}
