import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DomainOnboarding = _resolveComponent("DomainOnboarding")!
  const _component_prime_tabpanel = _resolveComponent("prime-tabpanel")!
  const _component_BulkDomainAndRulesUpload = _resolveComponent("BulkDomainAndRulesUpload")!
  const _component_prime_tabview = _resolveComponent("prime-tabview")!
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: _ctx.visible,
    modal: true,
    closable: true,
    class: "onboarding",
    "onUpdate:visible": _cache[1] || (_cache[1] = f => (_ctx.visible = f))
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("h3", null, "Add new domain", -1)
    ])),
    footer: _withCtx(() => [
      _createVNode(_component_prime_button, {
        label: "Cancel",
        icon: "pi pi-times",
        autofocus: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_prime_tabview, null, {
        default: _withCtx(() => [
          _createVNode(_component_prime_tabpanel, { header: "Single domain" }, {
            default: _withCtx(() => [
              _createVNode(_component_DomainOnboarding, { "onOnboarding:created": _ctx.handleNewDomain }, null, 8, ["onOnboarding:created"])
            ]),
            _: 1
          }),
          (_ctx.canMassLoad)
            ? (_openBlock(), _createBlock(_component_prime_tabpanel, {
                key: 0,
                header: "Domain and Rules import"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BulkDomainAndRulesUpload, {
                    consumer: _ctx.importConsumer,
                    label: "Select import file"
                  }, null, 8, ["consumer"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}