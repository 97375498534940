
    import { defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";
    import { fetchRealms } from "@/service/realms";
    import { RealmsBodyDTO } from "@/model/dto/realms";

    export default defineComponent({
        name: "Realms",
        props: {
            modelValue: {
                type: Array as PropType<Array<RealmsBodyDTO>>,
                required: true,
            },
            placeholder: {
                type: String,
                required: false,
                default: null,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
        },
        emits: ["update:modelValue"],
        setup(props, { emit }) {
            const autoCompleteRef: Ref<any> = ref(null);
            const selectedRealms: Ref<Array<RealmsBodyDTO>> = ref([]);
            const suggestedRealms: Ref<Array<RealmsBodyDTO>> = ref([]);
            let realms: Array<RealmsBodyDTO> = [];

            const clearInput = () => {
                if (autoCompleteRef.value) {
                    autoCompleteRef.value!.$refs.focusInput.value = "";
                }
            };

            const addRealm = (event: any) => {
                if (!event.query.trim().length) {
                    suggestedRealms.value = [...realms];
                } else {
                    suggestedRealms.value = realms.filter(realm => {
                        return realm.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            };

            const newRealm = () => {
                const newValue = autoCompleteRef.value!.$refs.focusInput.value;
                if (newValue.length) {
                    let newRealm: RealmsBodyDTO;
                    let matches = realms.findIndex(
                        realm => realm.name.toLowerCase() == newValue.toLowerCase(),
                    );
                    if (matches != -1) {
                        newRealm = realms[matches];
                    } else {
                        newRealm = { name: newValue };
                    }

                    if (selectedRealms.value.findIndex(realm => newRealm.name == realm.name) < 0) {
                        selectedRealms.value.push(newRealm);
                        emit("update:modelValue", selectedRealms.value);
                        clearInput();
                    }
                }
            };

            onMounted(async () => {
                const response = await fetchRealms();
                realms = response._embedded?.content || [];
                selectedRealms.value = props.modelValue;
            });

            watch(selectedRealms, value => {
                emit("update:modelValue", value);
            });

            return {
                autoCompleteRef,
                selectedRealms,
                suggestedRealms,
                addRealm,
                newRealm,
                clearInput,
            };
        },
    });
