import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "upload__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_prime_fileupload = _resolveComponent("prime-fileupload")!
  const _component_prime_spinner = _resolveComponent("prime-spinner")!
  const _component_prime_message = _resolveComponent("prime-message")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_prime_fileupload, {
        mode: "basic",
        name: "file",
        accept: ".xls,.xlsx",
        disabled: _ctx.disabled,
        "custom-upload": true,
        "choose-label": _ctx.buttonLabel,
        onSelect: _ctx.onSelect,
        onUploader: _ctx.xlsUploader
      }, null, 8, ["disabled", "choose-label", "onSelect", "onUploader"]),
      _withDirectives(_createVNode(_component_prime_spinner, {
        "stroke-width": "8",
        "animation-duration": ".5s"
      }, null, 512), [
        [_vShow, _ctx.loading]
      ])
    ]),
    _withDirectives(_createVNode(_component_prime_message, {
      severity: "success",
      closable: false
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", null, " Domains and rules are processed. Potential errors are listed in the downloaded Excel file (Downloads Folder). ", -1)
      ])),
      _: 1
    }, 512), [
      [_vShow, _ctx.success]
    ]),
    _withDirectives(_createVNode(_component_prime_message, {
      severity: "warn",
      closable: false
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("div", null, " Something went wrong. The result of the import could not be downloaded. Check the results manually and reupload if required. ", -1)
      ])),
      _: 1
    }, 512), [
      [_vShow, _ctx.warning]
    ]),
    _withDirectives(_createVNode(_component_prime_message, {
      severity: "error",
      closable: false
    }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "Error uploading file", -1)),
        _createElementVNode("div", null, _toDisplayString(_ctx.message), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.error]
    ])
  ]))
}