import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "value" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "value" }
const _hoisted_5 = {
  key: 1,
  class: "row"
}
const _hoisted_6 = { class: "value" }
const _hoisted_7 = {
  key: 2,
  class: "row"
}
const _hoisted_8 = { class: "value" }
const _hoisted_9 = {
  key: 3,
  class: "row"
}
const _hoisted_10 = { class: "value" }
const _hoisted_11 = {
  key: 4,
  class: "row"
}
const _hoisted_12 = { class: "value" }
const _hoisted_13 = {
  key: 5,
  class: "row"
}
const _hoisted_14 = { class: "value" }
const _hoisted_15 = {
  key: 6,
  class: "row"
}
const _hoisted_16 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CertificateStatus = _resolveComponent("CertificateStatus")!
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AcceleratorStatus = _resolveComponent("AcceleratorStatus")!
  const _component_DNSValidation = _resolveComponent("DNSValidation")!
  const _component_ValidationRecordDetails = _resolveComponent("ValidationRecordDetails")!
  const _component_ApiKeys = _resolveComponent("ApiKeys")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "label" }, "Responsible", -1)),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.domain.responsible), 1)
    ]),
    (_ctx.domain.realms.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "label" }, "Realms", -1)),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.flattenRealms(_ctx.domain.realms)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.domain.created != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "label" }, "Created", -1)),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formattedDate(_ctx.domain.created)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.domain.currentCertId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "label" }, "Current Certificate", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_CertificateStatus, {
              status: _ctx.domain.currentCertStatus
            }, null, 8, ["status"]),
            _cache[4] || (_cache[4] = _createTextVNode("  ")),
            _createVNode(_component_router_link, {
              to: _ctx.getCertificatePath(_ctx.domain.currentCertId),
              class: "link-no-decoration"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_prime_button, {
                  title: "Info",
                  icon: "pi pi-info",
                  class: "p-button-rounded p-button-small"
                })
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.domain.futureCertId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "label" }, "Future Certificate", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_CertificateStatus, {
              status: _ctx.domain.futureCertStatus
            }, null, 8, ["status"]),
            _cache[6] || (_cache[6] = _createTextVNode("  ")),
            _createVNode(_component_router_link, {
              to: _ctx.getCertificatePath(_ctx.domain.futureCertId),
              class: "link-no-decoration"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_prime_button, {
                  title: "Info",
                  icon: "pi pi-info",
                  class: "p-button-rounded p-button-small"
                })
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.external)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "label" }, "Global Accelerator ARN", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_AcceleratorStatus, {
              status: _ctx.domain.gaStatus
            }, null, 8, ["status"]),
            _createTextVNode(" " + _toDisplayString(_ctx.domain.ip) + " ( ", 1),
            _createVNode(_component_DNSValidation, {
              value: _ctx.domain.dnsGaValid
            }, null, 8, ["value"]),
            _cache[8] || (_cache[8] = _createTextVNode(" ) "))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.external)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "label" }, "Validation Record", -1)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_ValidationRecordDetails, {
              value: _ctx.domain.validationRecord
            }, null, 8, ["value"]),
            _createVNode(_component_DNSValidation, {
              value: _ctx.domain.validationRecordValid
            }, null, 8, ["value"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.external)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "label" }, "Api Keys", -1)),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_ApiKeys, {
              value: _ctx.domain.apiKeys,
              onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reloadDomain())),
              editable: _ctx.editable
            }, null, 8, ["value", "editable"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}