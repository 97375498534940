import axios, { axiosOptions } from "@/service/axios";
import {
    ApiKeyDTO,
    DomainStatusDTO,
    Filter,
    Filters,
    OnboardingRequestBodyDTO,
} from "@/model/dto/domain_v2";
import { Collection, Entity, Paged } from "@/model/dto/hateoas";
import { FilterMatchMode } from "primevue/api";

export async function fetchDomains(
    filters: Filters | null,
): Promise<Paged<DomainStatusDTO>> {
    const params = toParams(filters);

    const dtoList = await axios.get<Paged<DomainStatusDTO>>(`/domains`, {
        ...(await axiosOptions()),
        params: params,
    });
    return dtoList.data;
}

function toParams(filters: Filters | null): Record<string, string[] | string> {
    const result: Record<string, string[] | string> = {};

    if (filters) {
        if (filters.page) {
            result["page"] = String(filters.page);
        }
        result["size"] = String(filters.rows);

        const filterParam: string[] = [];
        for (const field in filters.filters) {
            toFilter(filterParam, field, filters.filters[field]);
        }
        if (filterParam.length > 0) {
            result["filter"] = filterParam;
        }

        if (filters.sortField) {
            const param =
                filters.sortField +
                "," +
                (filters.sortOrder == 1 ? "asc" : "desc");
            result["sort"] = param;
        }
    }
    return result;
}

function toFilter(filterParam: string[], field: string, filter: Filter): void {
    if (typeof filter.value === "string") {
        toFilterValue(
            filterParam,
            field,
            filter.matchMode,
            escape(String(filter.value)),
        );
        return;
    }

    if (filter.value !== null) {
        for (const v of filter.value) {
            toFilterValue(filterParam, field, filter.matchMode, escape(v));
        }
    }
}

function toFilterValue(
    filterParam: string[],
    field: string,
    matchMode?: string,
    value?: string,
): void {
    switch (matchMode) {
        case FilterMatchMode.STARTS_WITH:
            filterParam.push(field + ">" + value);
            break;
        case FilterMatchMode.CONTAINS:
            filterParam.push(field + ":" + value);
            break;
        case FilterMatchMode.ENDS_WITH:
            filterParam.push(field + "<" + value);
            break;
        case FilterMatchMode.EQUALS:
        case FilterMatchMode.IN:
            filterParam.push(field + "=" + value);
            break;
        case FilterMatchMode.NOT_EQUALS:
            filterParam.push(field + "!" + value);
            break;
        case FilterMatchMode.NOT_CONTAINS:
            filterParam.push(field + "^" + value);
            break;
    }
}

export async function getDomain(domain: string): Promise<DomainStatusDTO> {
    const dto = await axios.get<DomainStatusDTO>(
        `/domains/${domain}`,
        await axiosOptions(),
    );
    return dto.data;
}

export async function createDomain(
    domain: string,
    data: OnboardingRequestBodyDTO,
): Promise<DomainStatusDTO> {
    console.log("createDomain", domain, data);
    const dto = await axios.post<DomainStatusDTO>(
        `/domains/${domain}`,
        data,
        await axiosOptions(),
    );
    return dto.data;
}

export async function updateDomain(
    domain: DomainStatusDTO,
): Promise<DomainStatusDTO> {
    const dto = await axios.put<DomainStatusDTO>(
        domain._links.update.href,
        domain,
        await axiosOptions(),
    );
    return dto.data;
}

export async function deleteEntity(entity: Entity): Promise<void> {
    await axios.delete<String>(entity._links.delete.href, await axiosOptions());
}

export async function addApiKey(keys: Collection<ApiKeyDTO>): Promise<void> {
    await axios.post<String>(keys._links.add.href, null, await axiosOptions());
}

export async function postMassImport(xls: File): Promise<Blob> {
    const formData = new FormData();
    formData.set("file", xls);
    const created = await axios.post<Blob>(
        "/domains/rules",
        formData,
        await axiosOptions({
            headers: {
                "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
        }),
    );
    return created.data;
}
