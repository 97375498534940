
    import { ValidationStatus } from "@/model/redirect";
    import { defineComponent, onMounted, ref } from "vue";
    import { Ref } from "@vue/reactivity";
    import { getDomainsPath } from "@/composables/DomainStatusList";

    import { fetchProblems, fetchProblemsAsXls } from "@/service/problems";
    import { composeCheckedMessage, ProblemDTO } from "@/model/dto/problem";
    import { mapGetters } from "vuex";
    import { formattedDate } from "@/util/i18n";
    import download from "@/util/binary";

    // Defined outside of component to persist on back navigation

    export default defineComponent({
        name: "ProblemList",
        setup: function () {
            const problemDomains: Ref<ProblemDTO[]> = ref([]);
            const loading: Ref<boolean> = ref(true);
            const totalRecords: Ref<number> = ref(0);
            const loadingError: Ref<boolean> = ref(false);

            // loading
            onMounted(() => reloadData());

            const reloadData = async () => {
                await Promise.all([loadProblems()]);
            };

            const loadProblems = async () => {
                try {
                    loading.value = true;
                    problemDomains.value = await fetchProblems();
                } catch (e: any) {
                    loadingError.value = true;
                }
                loading.value = false;
            };

            const loadApiUrl = async () => {
                const blob = await fetchProblemsAsXls();
                download(blob, "problems");
            };

            return {
                reloadData,
                domains: problemDomains,
                totalRecords,
                loading,
                loadDomains: loadProblems,
                getDomainsPath,
                loadingError,
                ValidationStatus,
                composeCheckedMessage,
                loadExcelFile: loadApiUrl,
                formattedDate,
            };
        },
        computed: mapGetters("authentication", ["loggedIn", "isAdmin"]),
    });
